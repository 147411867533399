
export const useBandwidthSurplus = computed(()=>{
  const { account: tronAccount, isConnected } = tronComp.useAccountCurrent();
  
  let netSurplus = 0
  const { resource } = tronAccount
  if(!resource) netSurplus;
  
  if ('NetLimit' in resource) {
    // @ts-ignore
    netSurplus
      = filterNumber(resource?.freeNetLimit)
      + filterNumber(resource?.NetLimit)
      - filterNumber(resource?.freeNetUsed)
      - filterNumber(resource?.NetUsed)
  }
  else {
    netSurplus
      = filterNumber(resource?.freeNetLimit)
      - filterNumber(resource?.freeNetUsed)
  }
  
  return netSurplus;
})

function filterNumber(value: number | undefined) {
  return value ?? 0
}
